import logo from './logo_whtlb.png';
import whatalabLogo from './whatatools.png';
import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import { HashConnect } from 'hashconnect';
import axios from 'axios';
import { FaCheck, FaSpinner } from 'react-icons/fa';

function App() {
  const hashconnectRef = useRef(null);
  const walletMetadataRef = useRef(null);
  const initData = useRef(null);
  const verificationCode = useRef('');
  const discordIdCode = useRef('');
  const [discordError, setDiscordError] = useState(false);
  const [isPaired, setPaired] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    verificationCode.current = urlParams.get('code');
    discordIdCode.current = urlParams.get('state');
    setDiscordError(urlParams.get('error') != null || urlParams.get('code') === null || urlParams.get('state') === null);

    const initEvents = () => {
      hashconnectRef.current = new HashConnect(true);

      hashconnectRef.current.foundExtensionEvent.once((walletMetadata) => {
        console.log('wallet:', walletMetadata);
        walletMetadataRef.current = walletMetadata;
      });
      hashconnectRef.current.signRequestEvent.once((data) => {
        console.log('sign', data);
      });

      hashconnectRef.current.pairingEvent.once((pairingData) => {
        console.log('pairing:', pairingData);
        if (pairingData) setPaired(true);
      });
      hashconnectRef.current.connectionStatusChangeEvent.once((connectionStatus) => {
        console.log('connection:', connectionStatus);
      });
      hashconnectRef.current.acknowledgeMessageEvent.once((acknowledgeData) => {
        // do something with acknowledge response data
        console.log('acknoledge');
      });
    };

    const initHashConnect = async () => {
      const appMetadata = {
        name: 'WhataVerify',
        description: 'Wallet connect for discord communities',
        icon: '',
        url: 'localhost:3000',
      };
      try {
        initData.current = await hashconnectRef.current.init(appMetadata, 'mainnet', true);
        console.log(initData.current);
        let state = await hashconnectRef.current.connect();
        setPaired(initData.current.savedPairings.length !== 0);
        console.log(state);
      } catch (error) {
        console.log('error', error);
      }
    };

    initEvents();
    initHashConnect();
  }, []);

  const handleConnectClick = () => {
    if (isPaired) {
      hashconnectRef.current.disconnect(initData.current.topic);
      setPaired(false);
      return;
    }
    hashconnectRef.current.connectToLocalWallet(walletMetadataRef.current, initData.current.pairingString);
  };

  const handleSignClick = async () => {
    if (isVerified) {
      return;
    }
    setLoading(true);
    const connection = await hashconnectRef.current.sign(
      initData.current.topic,
      hashconnectRef.current.hcData.pairingData[0].accountIds[0],
      'Safety First!'
    );
    if (connection.success) {
      try {
        const response = await axios.post(
          `https://api.whatalab.io/dashboard/auth/verify-wallet/${hashconnectRef.current.hcData.pairingData[0].accountIds[0]}/${verificationCode.current}/${discordIdCode.current}`,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          setVerified(true);
        } else {
          setVerified(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setVerified(false);
    }
    setLoading(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  if (discordError) {
    return (
      <div className="app">
        <div className="top-bar">
          <img src={logo} alt="whatalab" className="logo" />
        </div>
        <div className="body">
          <div className="card">
            <div>
              <h4 className="error-message">Error while authenticating with discord. Please, try again</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <div className="top-bar">
        <img src={logo} alt="whatalab" className="logo" />
      </div>
      <div className="body">
        <div className="card">
          <img src={whatalabLogo} alt="Whatalab" className="whatalab-logo" />
          <div className="card-content">
            <button onClick={handleConnectClick} className="connect-button">
              {isPaired ? hashconnectRef.current.hcData.pairingData[0].accountIds[0] : 'Connect Wallet'}
            </button>
            {isPaired ? (
              <>
                <button onClick={handleSignClick} className={isVerified ? 'sign-complete' : 'sign-button'}>
                  { isLoading ? (
                    <FaSpinner className="spinner" />
                  ) : isVerified ? (
                    <FaCheck className="check-icon" />
                  ) : (
                    'Verify Wallet'
                  )}
                </button>
              </>
            ) : null}
            <div className="mobile-text">
                  <button onClick={handleModalOpen} className="mobile-button">
                    On mobile?
                  </button>
                  {showModal && (
                    <div className="modal">
                      <div className="modal-content">
                        <p>To complete the verification process on mobile:</p>
                        <ol>
                          <li>Copy the URL of this page.</li>
                          <li>Open the dApps section in Hashpack.</li>
                          <li>Paste the URL in the search bar.</li>
                          <li>Follow the verification steps.</li>
                        </ol>
                        <button onClick={handleCopyToClipboard} className="copy-button">
                          Copy URL to Clipboard
                        </button>
                        <p onClick={handleModalClose} className="close-button">
                          Close
                        </p>
                      </div>
                    </div>
                  )}
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
